import React from "react";

const work = [
    "Architected and developed scalable, realtime data sync pipelines with extreme focus on robustness and speed.",
    "Built and deployed inference services for text and image inference models at scale.",
    "Developed fast and accurate consumer facing search engines that do intelligent search based on semantic similarity.",
];

const experience = [
    {
        companyName: "Flyx AI",
        companyUrl: "https://flyx.ai",
        title: "Machine Learning Operations and Infrastructure Engineer",
        date: "December 2022 - March 2024",
    },
    {
        companyName: "DeweySmart",
        companyUrl: "https://deweysmart.com",
        title: "Full Stack Software Engineer",
        date: "June 2021 - December 2022",
    },
];

const links = [
    {
        name: "GitHub",
        url: "https://github.com/kkosana318",
    },
    {
        name: "LinkedIn",
        url: "https://www.linkedin.com/in/kesavkosana",
    },
    {
        name: "Resume",
        url: "https://drive.google.com/file/d/1dckMBRP2uUHq4cRPUhM7-ADdQm4Rb9d1/view?usp=drive_link",
    },
];

function App() {
    return (
        <div className="bg-[#1B1A1C] min-h-screen h-full py-10 flex flex-col items-center transition-opacity duration-700">
            <div className="w-[600px] max-w-[85%] flex flex-col gap-10 animate-fade-in-down">
                <h1 className=" text-gray-200 text-3xl">Kesav Kosana</h1>
                <div className="ABOUT flex flex-col gap-2">
                    <h1 className=" text-gray-200 text-xl">About</h1>
                    <div className="flex flex-col gap-4 text-gray-400">
                        <span>
                            I'm an early software engineer with two years of experience. I view engineering as an art and I care deeply about striving
                            for elegance and craftsmanship in my work.
                        </span>
                        <span>A very brief summary of my recent work:</span>
                        <ul className="pl-5 list-disc flex flex-col gap-2">
                            {work.map((w, i) => (
                                <li key={i}>{w}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="EXPERIENCE flex flex-col gap-2">
                    <h1 className=" text-gray-200 text-xl">Experience</h1>
                    <div className="flex flex-col gap-4 text-gray-400">
                        <ul className="flex flex-col gap-6">
                            {experience.map((w, i) => (
                                <li className="flex flex-col gap-1" key={i}>
                                    <a href={w.companyUrl} target="_blank" rel="noopener noreferrer" className="text-blue-400">
                                        {w.companyName}
                                    </a>
                                    <span>{w.title}</span>
                                    <span>{w.date}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="LINKS flex flex-col gap-2">
                    <h1 className=" text-gray-200 text-xl">Links</h1>
                    <div className="flex flex-col gap-4 text-gray-400">
                        <ul className="flex flex-col gap-2">
                            {links.map((w, i) => (
                                <li key={i}>
                                    <a href={w.url} target="_blank" rel="noopener noreferrer" className="text-blue-400">
                                        {w.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
